import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { MovieService } from '@usheru-hff/sdk/movie';
import { Competition } from './entities/competition.model';
import { CompetitionConfig, ConsumerLead, Movie } from '@usheru-hff/shared/domain';
import { CompetitionQuestion } from './entities/competition-question.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CompetitionsService } from './infrastructure/competitions.service';
import { LocationService } from '@usheru-hff/shared/utils-location';
import { MetatagsService } from '@usheru-hff/shared/utils-metatag';
import { TrackingService } from '@usheru-hff/shared/utils-tracking';
import { PageLoaderService } from '@usheru-hff/shared/utils-navigation';
import { AnswerSelection } from './entities/answer-selection.model';
import { TemplatesService } from '@usheru-hff/shared/utils';
import { ENVIRONMENT, Environment } from '@environment';

@Component({
  selector: 'ush-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.scss']
})
export class CompetitionComponent implements OnChanges {
  movieId: string;
  movie!: Movie;
  competition!: Competition;
  competitionPlayed!: boolean;
  movieLogo?: boolean;

  visible: boolean = true;

  @Output()
  close = new EventEmitter<void>();

  @ViewChild('competitionTemplate')
  competitionTemplate!: TemplateRef<any>;

  @ViewChild('termsTemplate')
  termsTemplate!: TemplateRef<any>;

  @ViewChild('rulesTemplate')
  rulesTemplate!: TemplateRef<any>;

  activeSection: 'competition' | 'terms' | 'rules' | 'thankYouScreen' = 'competition';
  background: string | undefined;

  faLongArrowAltLeft = faLongArrowAltLeft;

  consumerLead: ConsumerLead;
  answers: any = {};
  allQuestionsAnswered = false;
  question!: CompetitionQuestion;
  competitionConfig: CompetitionConfig;
  customHeaderTemplate: TemplateRef<any>;
  customFooterTemplate: TemplateRef<any>;
  customBackButtonTrMsg: string;
  customBackButtonUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private competitionsService: CompetitionsService,
    private locationService: LocationService,
    private location: Location,
    private metaTagsService: MetatagsService,
    @Inject(ENVIRONMENT) private env: Environment,
    private movieService: MovieService,
    private trackingService: TrackingService,
    private cd: ChangeDetectorRef,
    private pageLoader: PageLoaderService,
    private templateService: TemplatesService
  ) {
    this.consumerLead = <ConsumerLead>{
      type: 'competition',
      longitude: this.locationService.userLocation?.longitude,
      latitude: this.locationService.userLocation?.latitude,
      score: 0
    };
    // getting movie id from route
    const params = this.route.snapshot.params;
    this.movieId = params['movieId'];

    // getting competition info about the movie
    this.competitionsService.getCompetition(this.movieId).subscribe(competition => {
      if (competition) {
        if (competition?.played) {
          this.competitionPlayed = true;
        } else {
          this.consumerLead.idCompetition = `${competition.id}`;
          this.question = competition.questions[0];
        }

        this.competition = competition;

        this.background = competition.backgroundImageUrl;
      }

      this.movieService.getMovie(this.movieId).subscribe(movie => {
        this.movie = movie;
        this.pageLoader.hidePageLoader();
        if (!competition) {
          if (this.movie) {
            const pre = this.env?.customFeatures?.nonPrefixedMovieDetailRoutes ? '/' : '/movie/';
            this.router.navigateByUrl(`${pre}${this.movie.friendlyUrl}`, { replaceUrl: true });
          } else {
            this.router.navigateByUrl(`/not-found`, { replaceUrl: true });
          }
          return;
        } else if (!competition?.played) {
          // If played not tracking
          this.trackingService.trackEvent({
            action: 'CompetitionPageView',
            properties: {
              category: 'Competitions',
              label: `${competition.title} @ ${this.movie.title}`,
              movie: this.movie.title,
              idMovie: this.movie.id,
              imdb: this.movie?.imdb,
              genresMovie: this.movie.genres?.map(g => g.slug),
              tagsMovie: this.movie.tags?.map(t => t.friendlyUrl),
              competitionTitle: competition.title,
              idCompetition: competition.id
            }
          });
        }
        this.loadCompetitionConfig(this.movie);
      });
    });
  }

  ngOnInit() {
    this.addCompetitionMetatags();
  }

  loadCompetitionConfig(movie: Movie) {
    this.competitionConfig = this.env.competitionsConfig?.find(config => config.movieSlug == movie.friendlyUrl);
    this.movieLogo = this.competitionConfig?.movieLogo;
    if (this.competitionConfig.headerContent)
      this.customHeaderTemplate = this.templateService.get(this.competitionConfig.headerContent);
    if (this.competitionConfig.footerContent)
      this.customFooterTemplate = this.templateService.get(this.competitionConfig.footerContent);
    this.customBackButtonTrMsg = this.competitionConfig.backButtonCustomString;
    this.customBackButtonUrl = this.competitionConfig.backButtonCustomUrl;
  }

  onTermsClicked() {
    this.activeSection = 'terms';
  }

  //TODO update this in the future. This if not do the tracking and redirections
  ngOnChanges(changes: SimpleChanges) {
    const competitionChange = changes['competition'];
    if (competitionChange && this.competition) {
      const competition = competitionChange.currentValue;
      this.question = competition.questions[0];
      this.consumerLead.idCompetition = this.competition.id?.toString();
    }
  }

  formSubmitted(): void {
    this.competitionsService.competitionSubmitted(this.movieId);
    this.activeSection = 'thankYouScreen';

    this.trackingService.trackEvent({
      action: 'Lead',
      properties: {
        category: 'Competitions',
        label: `${this.competition.title} @ ${this.movie.title}`,
        movie: this.movie.title,
        idMovie: this.movie.id,
        imdb: this.movie?.imdb,
        tagsMovie: this.movie.tags?.map(t => t.friendlyUrl),
        genresMovie: this.movie.genres?.map(g => g.slug),
        competitionTitle: this.competition.title,
        idCompetition: this.competition.id
      }
    });
  }

  goBack(): void {
    this.location.back();
  }

  answerPicked(answerSelection: AnswerSelection): void {
    this.answers[answerSelection.questionId] = answerSelection.answer;

    const questionIndex = this.competition.questions?.findIndex(q => q == this.question);

    if (this.competition && questionIndex < this.competition.questions.length - 1) {
      // changing of num of question after a couple of seconds
      this.visible = false;
      setTimeout(() => {
        this.visible = true;
        this.question = this.competition.questions[questionIndex + 1];
      }, 1000);
    } else {
      this.allQuestionsAnswered = true;
      // todo: test
      setTimeout(() => {
        document.querySelector('#form-subscrition')?.scrollIntoView({ behavior: 'smooth' });
      }, 10);
    }

    this.recomputeConsumerLeadScore();
  }

  private recomputeConsumerLeadScore(): void {
    let sum = 0;

    Object.keys(this.answers).forEach(questionId => {
      const answer = this.answers[questionId];
      if (answer?.isCorrect) {
        sum += 100;
      }
    });

    this.consumerLead.score = sum / this.competition.questions.length;
  }

  addCompetitionMetatags() {
    const title = `meta.page.competition.title`;
    const description = `meta.page.competition.description`;

    this.metaTagsService.setMetaTags(
      {
        url: this.location.path(),
        title: title,
        description: description
      },
      {
        siteName: this.env.siteName || 'SITENAME',
        movie: this.movie?.title || ''
      }
    );
  }
}
